.form-tawreed {
  .form-grid.cart-form {
    .cart-form-status-messages {
      .p-message {
        margin: 0 0;
      }
    }
  }
}

.image-card {
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px !important;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}

.bonuses {
  margin: -0.25rem;
  .bonuses-item {
    margin: 0.25rem;
    padding: 0.25rem 0.5rem;
    display: inline-block;
    border: 1px solid #495057;
    border-radius: 0.25rem;
  }
}
.selectedBonus{
    border: 2px solid #3BAB3E;
    border-radius: 8px;
}
