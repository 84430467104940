.tawreed-detail-view {
  .tawreed-detail-view-toolbar {
    .p-toolbar-group-left {
      >.p-button+.p-button {
        margin-left: 10px;
      }
    }
  }
  .p-dataview {
    width: 100%;
    .p-dataview-header {
      border: 1px solid var(--surface-border);
      overflow: hidden;
      border-radius: var(--border-radius) var(--border-radius) 0 0;
    }
    .p-dataview-content {
    //   height: calc(100vh - 20rem);
      //
      padding: auto;
      border: 1px solid var(--surface-border) !important;
      overflow-y: auto;
      overflow-x: hidden;
      border-radius: 0 0 var(--border-radius) var(--border-radius);
    }
    .p-grid{
        display: flex;
        flex-wrap: nowrap;
        flex-direction: row;
        overflow-x: auto;
        scroll-behavior: smooth;
        padding: 5px;
        gap:4px;
        &:focus-visible{
            outline: none !important;
        }
        &::-webkit-scrollbar {
            height: 8px; /* Height of horizontal scrollbar */
          }

          &::-webkit-scrollbar-thumb {
            background-color: rgba(0, 0, 0, 0.5); /* Scrollbar thumb color */
            border-radius: 10px; /* Rounded corners */
            border: 2px solid transparent; /* Space between thumb and track */
            background-clip: content-box; /* Makes the thumb smaller */
          }

          &::-webkit-scrollbar-track {
            background: transparent; /* Scrollbar track color */
          }

          /* Firefox */
          scrollbar-width: thin;
          scrollbar-color: rgba(0, 0, 0, 0.5) transparent;


    }
    .tawreed-detail-view-item {
      width: 100%;
      display: flex;
      padding: 5px;
      border-radius: 8px;
      border: 2px solid #edececb5 !important;
      margin: 10px;
      cursor: pointer;
      >.tawreed-detail-view-item-checkbox {
        margin-right: 5px;
      }
      &.p-highlight {
        background: rgb(240, 246, 241);
        color: var(--primary-color);
        border: 2px solid rgb(59,171,62) !important;
      }
    }
  }
  //
  .p-dataview:not(.p-disabled) {
    .tawreed-detail-view-item:not(.p-highlight):not(.p-disabled):hover {
      color: #495057;
      background: #e9ecef;
    }
  }
}

html {
  &.rtl {
    .tawreed-detail-view {
      .tawreed-detail-view-toolbar {
        .p-toolbar-group-left {
          >.p-button+.p-button {
            margin-left: initial !important;
            margin-right: 10px;
          }
        }
      }
      .p-dataview {
        .tawreed-detail-view-item {
          >.tawreed-detail-view-item-checkbox {
            margin-right: initial;
            margin-left: 5px;
          }
        }
      }
    }
  }
}

