.notifications .p-tabview-panels {
    padding: 0rem !important;
}

.notifications .p-overlaypanel-content {
    padding: 0rem !important;
}

.notification-item-image-div {
    height: 100px;
    max-width: 150px !important;
}

.notification-item-image {
    background-color: white;
    overflow: hidden;
    border-radius: var(--border-radius);
    width: 70%;
    object-fit: fill;
    aspect-ratio: 1;
}

.rts___btn {
    border: none !important;
    box-shadow: none !important;
    border-radius: 0px !important;
}



.rts___tabs {
    border: 1px solid #dee2e6 !important;
    border-width: 2px 0 2px 0 !important;
    width: 100%;
    scrollbar-width: thin !important;
}

.rts___tab {
    padding: 10px 0px !important;
}

.rts___tab .p-c {
    color: #6c757d;
    font-weight: 700;
}

.rts___tab .layout-topbar-button {

    padding: 1rem !important;
}

.rts___tab___selected .p-c {
    color: #3cab3e;
}

.rts___nav___btn:hover {
    background: #3cab3e !important;
    border-color: #3cab3e !important;
    color: #3cab3e !important;
    border: 1px solid !important;
}

.rts___tab___selected {
    background: #ffffff !important;
    border-color: #3cab3e !important;
    color: #3cab3e !important;
    border: 1px solid !important;
    border-width: 0 0 2px 0 !important;
    padding-bottom: 10px;
}